// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"screen": "only screen",
	"print": "only print",
	"mobile-small": "only screen and (max-width: 359.99px)",
	"mobile-small-portrait": "only screen and (max-width: 359.99px) and (orientation: portrait)",
	"mobile-small-landscape": "only screen and (max-height: 359.99px) and (orientation: landscape)",
	"mobile-large": "only screen and (min-width: 360px) and (max-width: 559.99px)",
	"mobile-large-portrait": "only screen and (min-width: 360px) and (max-width: 559.99px) and (orientation: portrait)",
	"mobile-large-landscape": "only screen and (min-height: 360px) and (max-height: 559.99px) and (orientation: landscape)",
	"mobile-any": "only screen and (max-width: 359.99px), only screen and (min-width: 360px) and (max-width: 559.99px)",
	"mobile-any-portrait": "only screen and (max-width: 359.99px) and (orientation: portrait), only screen and (min-width: 360px) and (max-width: 559.99px) and (orientation: portrait)",
	"mobile-any-landscape": "only screen and (max-height: 359.99px) and (orientation: landscape), only screen and (min-height: 360px) and (max-height: 559.99px) and (orientation: landscape)",
	"tablet-small": "only screen and (min-width: 560px) and (max-width: 885.99px)",
	"tablet-small-portrait": "only screen and (min-width: 560px) and (max-width: 885.99px) and (orientation: portrait)",
	"tablet-small-landscape": "only screen and (min-height: 560px) and (max-height: 885.99px) and (orientation: landscape) and (max-aspect-ratio: 21/10)",
	"tablet-large": "only screen and (min-width: 886px) and (max-width: 1003.99px) and (max-aspect-ratio: 21/10)",
	"tablet-any": "only screen and (min-width: 560px) and (max-width: 885.99px), only screen and (min-width: 886px) and (max-width: 1003.99px) and (max-aspect-ratio: 21/10)",
	"desktop-any": "only screen and (min-width: 1004px) and (max-width: 1419.99px), only screen and (min-width: 1420px)",
	"desktop-small": "only screen and (min-width: 1004px) and (max-width: 1419.99px)",
	"desktop-large": "only screen and (min-width: 1420px)"
};
export default ___CSS_LOADER_EXPORT___;
