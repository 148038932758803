import { AppProps, RegisterApplicationConfig } from 'single-spa';
import routes from '../routes.ts';

const app: RegisterApplicationConfig = {
  name: '@sx/mf-advisergw-groups',
  activeWhen: routes.groups.activeWhen,
  app: ({ name }: AppProps) => {
    return System.import(name);
  },
  customProps: {
    mountElementId: 'content-body',
  },
};

export default app;
