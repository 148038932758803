import { AppProps, RegisterApplicationConfig } from 'single-spa';

const app: RegisterApplicationConfig = {
  name: '@sx/mf-advisergw-authenticator',
  activeWhen: () => true,
  app: ({ name }: AppProps) => {
    return System.import(name);
  },
  customProps: {
    mainContentElementId: 'content-body',
  },
};

export default app;
