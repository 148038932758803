import { registerApplication, start } from 'single-spa';

import * as applications from './applications';

Object.values(applications).map(registerApplication);

// Let's set a flag for check-for-unsupported-browser.ts, so that it can know we have started
window.__APP_STARTED = true;

start();
