import { AppProps, RegisterApplicationConfig } from 'single-spa';
import routes from '../routes.ts';

const app: RegisterApplicationConfig = {
  name: '@sx/mf-advisergw-header',
  activeWhen: (location) =>
    Object.values(routes)
      .filter((route) => ![routes.auth, routes.migrateMe].includes(route))
      .some(({ activeWhen }) => activeWhen(location)),
  app: ({ name }: AppProps) => {
    return System.import(name);
  },
  customProps: {
    mountElementId: 'content-header',
  },
};

export default app;
